import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppText } from "../../ui";

interface MemberProps {
  data: {
    id: number;
    name: string;
    image: string;
    title: string;
    bio: string;
  };
}

export const Member: React.FC<MemberProps> = ({ data }) => {
  return (
    <Container>
      <Img src={`/${data.image}`} alt={`${data.name} picture`} />
      <TextContainer>
        <AppText
          color="#292032"
          fontSize={16}
          mobileFontSize={16}
          fontWeight={700}
          style={{ fontFamily: theme.PRIMARY_FONT }}
        >
          {data.name}
        </AppText>
        <AppText
          color={theme.PRIMARY_COLOR}
          fontSize={12}
          mobileFontSize={12}
          fontWeight={600}
          textAlign="center"
          textTransform="uppercase"
        >
          {data.title}
        </AppText>
        <AppText fontSize={12} mobileFontSize={12} textAlign="center" color={theme.SLATE_COLOR}>
          <em>{data.bio}</em>
        </AppText>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 16px 8px -40px;

  @media ${mobileSize} {
    margin-right: 0;
  }
`;

const Img = styled.img`
  position: relative;
  left: 40px;
  object-fit: cover;
  width: 76px;
  height: 76px;
  border: 2px solid ${theme.LIGHT_GREY};
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${theme.WHITE_COLOR};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 120px;
  width: 300px;
  padding: 16px;
  padding-left: calc(16px + 38px);
  border-radius: 16px;
  border: 2px solid ${theme.LIGHT_GREY};
  box-sizing: border-box;

  text-align: center;

  & > p {
    margin: 0;
  }
`;
