import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { laptopSize, mobileSize } from "../../../util/variables";
import { AppText, AppTitle } from "../../ui";

export const HowImprintWorks = () => {
  const TEXT_SIZE = 16;
  return (
    <Container>
      <ContentContainer>
        <Section>
          <Title fontSize={36} color={theme.PRIMARY_COLOR}>How Imprint Works</Title>
          <AppText
            fontSize={16}
            mobileFontSize={16}
          >
            At Imprint, our mission is to make the world&apos;s most important knowledge easy to learn, internalize, and apply to your life.
            Behind each of our visual lessons is a dedicated team of experts and a meticulous process designed to maximize clarity and make learning stick.
          </AppText>
          <ListText>
            Everything you read on Imprint has been:
          </ListText>
          <ul>
            <MarginLi>
              <ListText>
                Written and reviewed by experts who deeply understand the topic area
              </ListText>
            </MarginLi>
            <MarginLi>
              <ListText>
                Designed and animated by artists who specialize in complex visual communication
              </ListText>
            </MarginLi>
            <MarginLi>
              <ListText>
                Reviewed and revised multiple times for clarity and accuracy
              </ListText>
            </MarginLi>
            <MarginLi>
              <ListText>
                Updated based on user feedback to eliminate points of confusion
              </ListText>
            </MarginLi>
          </ul>
          <Note>
            <AppText
              fontSize={TEXT_SIZE}
              mobileFontSize={TEXT_SIZE}
              style={{ marginBottom: "0px" }}
            >
              Read on to learn more about how we research, write, design, ensure quality, respond to feedback, and choose topics.
            </AppText>
          </Note>
        </Section>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${theme.GREY_COLOR};
`;

const ContentContainer = styled.div`  
  width: 50%;
  padding: 64px 0px;
  @media ${laptopSize} {
    width: 60%;
  }
  @media ${mobileSize} {
    width: 100%;
    padding: 80px 24px;
    padding-bottom: 0;
  }
`;

const Title = styled(AppTitle as any)`
  margin-bottom: 30px;
  @media ${mobileSize} {
    width: 80%;
  }
`;

const Section = styled.section``;

const Note = styled.div`
  padding: 32px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    padding: 16px;
    margin-bottom: 40px;
  }
`;

const MarginLi = styled.li`
  margin-left: 1rem;
`;

const ListText = styled(AppText as any)`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  @media ${mobileSize} {
    font-size: 16px;
  }
`;
