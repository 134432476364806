import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { laptopSize, mobileSize } from "../../../util/variables";
import { AppText } from "../../ui";
import { Member } from "./Member";
import { designTeam, writingTeam } from "./MembersData";

export const ImprintProcess = () => {
  const TEXT_SIZE = 16;

  return (
    <Container>
      <ContentContainer>
        <Section>
          <SectionTitle>How we research and write</SectionTitle>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Every piece on Imprint begins with thorough research. For book summaries, the writer reads the books
            and researches the author, their other works, and the topic area in general. For other lessons, we
            work directly with experts and academics, or we collect information from highly credible sources like
            major works in the field or courses at prominent universities.
          </AppText>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            This research culminates in an outline, which focuses on the specific questions we want the piece to
            answer. Then, the writer creates the script that will be presented in the app, as well as quiz questions
            and other interactive exercises that will be included to help readers retain what they learn, long after
            they&apos;ve finished reading.
          </AppText>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Our writing team is led by Olivia Kang, PhD. who previously taught psychology at Harvard. She reviews every
            script, and works with outside experts to generate novel ways of explaining concepts as clearly as possible.
          </AppText>
          <MembersTitle>MEET A FEW MEMBERS OF OUR WRITING TEAM:</MembersTitle>
          <MembersContainer>
            {writingTeam.map(member => <Member key={`${member.name}-${member.id}`} data={member} />)}
          </MembersContainer>
        </Section>
        <Section>
          <SectionTitle>How we design</SectionTitle>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            The writers and editors collaborate with our creative team to design visuals that will accompany the script.
            Together, they create sketches of the diagrams, charts, and infographic sequences that will be illustrated,
            animated, and viewed in the app.
          </AppText>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            These visuals are never just decoration; we believe they are a core part of an effective learning experience. The best visuals succeed at three goals:
          </AppText>
          <StyledUl>
            <MarginLi>They help you stay engaged and focused while you read</MarginLi>
            <MarginLi>They help you quickly understand complex ideas</MarginLi>
            <MarginLi>They make it easier to remember what you&apos;ve learned</MarginLi>
          </StyledUl>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Imprint&apos;s visual lessons are meant to clarify complex concepts, rather than simplify them. Our aim is to educate
            readers in a short amount of time, without sacrificing depth of understanding. To this aim, our design team includes
            designers, illustrators, and animators with vast and varied experience communicating complex ideas in media,
            advertising, and educational settings.
          </AppText>
          <MembersTitle>MEET A FEW MEMBERS OF OUR CREATIVE TEAM:</MembersTitle>
          <MembersContainer>
            {designTeam.map(member => <Member key={`${member.name}-${member.id}`} data={member} />)}
          </MembersContainer>
        </Section>
        <Section>
          <SectionTitle>How we ensure clarity and accuracy</SectionTitle>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            For every new release, our writing and visuals go through multiple rounds of copyediting, fact-checking, and revisions, from
            internal and outside reviewers. The goal during this phase is to eliminate not just errors, but also any points of confusion.
          </AppText>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            If anyone needs to go back and read a sentence again, or feels, even briefly, like they didn&apos;t quite understand a specific
            statement, we take that as a sign that the piece can be meaningfully improved. We aim to release lessons that you&apos;ll
            understand in full, the first time through. Learning should feel effortless.
          </AppText>
        </Section>
        <Section>
          <SectionTitle>How we respond to feedback</SectionTitle>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            The Imprint app makes it extremely easy to submit feedback on our content. You can submit feedback on a specific card, or on
            a whole chapter. Every time feedback comes in, a notification gets posted to the entire Imprint team.
          </AppText>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Some user feedback is about errors or typos, and these are nearly always resolved in a matter of hours. Other times, readers
            offer suggestions on how to improve our explanations. This feedback from our readers is massively important, and an essential
            part of the Imprint process. We continue updating our lessons long after they&apos;re released, as we continue to respond to user feedback.
          </AppText>
        </Section>
        <Section>
          <SectionTitle>How we choose topics</SectionTitle>
          <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Once we&apos;ve released our newest content, how do we decide what to work on next? We choose books and topics to cover based on these factors and others:
          </AppText>
          <StyledUl>
            <MarginLi>Recommendations from our readers</MarginLi>
            <MarginLi>The New York Times, Amazon, and other bestseller lists</MarginLi>
            <MarginLi>Major news events and popular topics on social media</MarginLi>
            <MarginLi>Our own team&apos;s interests and areas of expertise</MarginLi>
          </StyledUl>
          {/* <AppText
            fontSize={TEXT_SIZE}
            mobileFontSize={TEXT_SIZE}
          >
            Interested in a topic we haven&apos;t covered yet? Submit your recommendations <a href="#"><b>here</b></a>.
          </AppText> */}
        </Section>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${theme.WHITE_COLOR};
`;

const ContentContainer = styled.div`  
  width: 50%;
  padding: 80px 0;
  padding-bottom: 40px;
  & > section:last-child {
    padding: 0;
  }
  @media ${laptopSize} {
    width: 60%;
  }
  @media ${mobileSize} {
    width: 100%;
    padding: 48px 24px;
    padding-bottom: 8px;
  }
`;

const Section = styled.section`
  padding-bottom: 48px;
  @media ${mobileSize} {
    padding-bottom: 24px;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 24px;
  font-weight: 700;
  color: #292032;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  margin-bottom: 24px;
  /* @media ${mobileSize} {
    justify-content: flex-start;
  } */
`;

const MembersTitle = styled.h3`
  margin-bottom: 16px;
  color: ${theme.SLATE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
`;

const MarginLi = styled.li`
  margin-left: 1rem;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: normal;
  font-size: 16px;
  color: ${theme.SLATE_COLOR};
`;

const StyledUl = styled.ul`
  margin-bottom: 40px;
`;
