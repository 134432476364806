import styled from "styled-components";
import * as React from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
// import { mobileSize } from "../util/variables";
import { HowImprintWorks } from "../components/PageSegments/why-trust-imprint/HowImprintWorks";
import { ImprintProcess } from "../components/PageSegments/why-trust-imprint/ImprintProcess";
import { BasicLayout } from "../layouts/BasicLayout";

export default ({ data }) => {
  return (
    <BasicLayout noFollow>
      <Main>
        {/* <NavBar initiallyWhite /> */}
        {/* <WhiteDiv /> */}
        <HowImprintWorks />
        <ImprintProcess />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

// const WhiteDiv = styled.div`
//   width: 100%;
//   height: 140px;
//   background-color: transparent;
//   @media ${mobileSize} {
//     height: 55px;
//   }
// `;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
