export const writingTeam = [
  {
    id: 1,
    name: "Olivia Kang",
    image: "Liv Kang Circle.png",
    title: "Director of Learning",
    bio: "PhD, Psychological & Brain Sciences, Dartmouth",
  },
  {
    id: 2,
    name: "Jasmyne Eastmond",
    image: "Jasmyne Eastmond Circle.png",
    title: "Content Editor",
    bio: "MPhil, English Studies, University of Cambridge",
  },
  {
    id: 3,
    name: "John Kaag",
    image: "John Kaag Circle.png",
    title: "Contributing Author",
    bio: "Professor and Chair of Philosophy, UMass Lowell",
  },
  {
    id: 4,
    name: "Jay Van Bavel",
    image: "Jay Van Bavel circle.png",
    title: "Contributing Author",
    bio: "Associate Professor of Psychology & Neural Science, NYU",
  },
];

export const designTeam = [
  {
    id: 1,
    name: "Maren Morstad",
    image: "Maren Morstad Circle.png",
    title: "Contributing Creative Director",
    bio: "Hyper Island, Google, Dell, NatGeo",
  },
  {
    id: 2,
    name: "Crosby Ignasher",
    image: "Crosby Ignasher Circle.png",
    title: "Lead Animator",
    bio: "SCAD, The Wall Street Journal, MasterCard",
  },
  {
    id: 3,
    name: "Alex Cheung",
    image: "Alex Cheung Circle.png",
    title: "Senior Visual Designer",
    bio: "UVA, Vox",
  },
  {
    id: 4,
    name: "Stephanie Lin",
    image: "Stephanie Lin circle.png",
    title: "Contributing Illustrator",
    bio: "SVA, Scholar, Psyop",
  },
];
